import {Controller} from "@hotwired/stimulus"
import {getDistance} from "geolib"
import mapbox from "mapbox-gl"
import * as turf from "@turf/turf"
import {FetchRequest, post, get} from "@rails/request.js"
// import html2canvas from "html2canvas";

// Connects to data-controller="pdf"
export default class extends Controller {
  static values = {
    token: String,
    centerIcon: String,
    inspection: Object,
    mapCenter: {
      type: Object,
      default: {
        lat: {type: Number, default: 0.1},
        lng: {type: Number, default: 0.1}
      }
    }
  }

  map_container_id = 'map_container_for_pdf_id'

  connect() {
    console.log('pdf controller')
    mapbox.accessToken = this.tokenValue
  }

  async loadMap() {
    this.toggleIcon(true)
    const div = document.createElement("div")
    div.style.width = '816px'
    div.style.height = '1080px'
    // div.style.marginLeft = "100px"
    div.hidden = true
    div.id = this.map_container_id
    document.body.appendChild(div)

    const productiveSpaces = this.inspectionValue?.embedded_productive_units?.flatMap(pu => pu.embedded_productive_spaces).filter(a => !!a) || []

    const images = {}
    for await (const productiveSpace of productiveSpaces) {
      if (productiveSpace.bounds.length === 0) continue;
      await this.initializeProductiveSpaceInfo(productiveSpace)
      const data = await this.takeScreenShoot()
      images[productiveSpace.realm_id] = data
      // await this.uploadScreenShoot(data, productiveSpace.realm_id)
    }

    const totalDistance = this.getDistance()


    try {
      const response = await this.createPdf(images, totalDistance)

      if (response.ok) {
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = url
        const entity = this.inspectionValue.embedded_entity
        const doneAt = this.inspectionValue.done_at ? new Date(this.inspectionValue.done_at) : undefined
        let formattedDate
        if (doneAt) {
          formattedDate = `${doneAt.getFullYear()}-${doneAt.getMonth() + 1}-${doneAt.getDate()}`
        }
        link.download = `${entity?.name ?? ''}_${entity?.surname ?? ''}_${formattedDate}.pdf`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
        // window.open(url, '_blank')
      } else {
        console.error('error generate pdf')
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.toggleIcon(false)
    }

    // window.open(`/pdfs/review.pdf?inspection_id=${this.inspectionValue._id}`, '_blank')

    // const container = document.getElementById('map_container_for_pdf_id')
    // const canvas = container.querySelector('canvas')
    // console.log(canvas)
    // const dataURL = canvas.toDataURL('image/png');
  }

  toggleIcon(disabled) {
    const downloadButton = document.getElementById('download_pdf_button')
    const icon = downloadButton.querySelector('span')
    icon.hidden = disabled
    icon.className = disabled ? "p-1 spinner-border spinner-border-sm me-2 text-light" : "bi bi-file-earmark-pdf-fill me-2"

    if (disabled) {
      icon.setAttribute('role', 'status')
      icon.setAttribute('aria-hidden', "true")
    } else {
      icon.removeAttribute('role')
      icon.removeAttribute('aria-hidden')
    }

    disabled ? downloadButton.classList.add('disabled') : downloadButton.classList.remove('disabled')
  }

  async initializeProductiveSpaceInfo(plot) {
    return new Promise((resolve, reject) => {
      const coord = new mapbox.LngLat(plot.entrance_point?.longitude ?? 0.1, plot.entrance_point?.latitude ?? 0.1, plot.entrance_point?.altitude ?? 0);

      this.map = new mapbox.Map({
        container: this.map_container_id,
        style: "mapbox://styles/mapbox/satellite-streets-v12",
        center: coord,
        zoom: 16,
        preserveDrawingBuffer: true,
      })

      const bounds = new mapbox.LngLatBounds();
      plot.bounds.forEach(coord => {
        bounds.extend([coord.longitude, coord.latitude]);
      });

      this.map.on('load', async () => {
        this.map.fitBounds(bounds, {
          padding: 20,
          maxZoom: 16
        });

        await this.addCenterPoint(plot)
        this.drawPolygon(plot)
        this.drawItems(plot)
        resolve()
      })
    })

  }

  drawPolygon(plot) {
    const circle = turf.circle(plot.bounds.flatMap(b => [b.longitude, b.latitude]), 0.2, {units: "kilometers"})
    this.map.fitBounds(turf.bbox(circle))

    let initPoint, endPoint
    let c = plot.bounds.map((bound) => [bound.longitude, bound.latitude])


    if (plot.bounds.length > 0) {
      initPoint = [plot.bounds[0].longitude, plot.bounds[0].latitude]
      c.push(initPoint)
    }

    const feature = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: c
      }
    }

    if (!this.map.getSource(`inspections_productive_units_productive_space_${plot._id}`)) {
      this.map.addSource(`inspections_productive_units_productive_space_${plot._id}`, {
        type: "geojson",
        data: {type: "FeatureCollection", features: [feature]}
      })
    }

    if (!this.map.getLayer(`inspections_productive_units_productive_space_${plot._id}`)) {
      this.map.addLayer({
        id: `inspections_productive_units_productive_space_${plot._id}`,
        type: "fill",
        // type: "line",
        source: `inspections_productive_units_productive_space_${plot._id}`,
        layout: {
          visibility: "visible",
          // "line-join": "round", "line-cap": "round"
        },
        paint: {
          "fill-color": "yellow", "fill-opacity": 0.4,
          // "line-color": "yellow", "line-width": 2
        }
      })
    }

    if (!this.map.getLayer(`inspections_productive_units_productive_space_${plot._id}_limits`)) {
      this.map.addLayer({
        id: `inspections_productive_units_productive_space_${plot._id}_limits`,
        // type: "fill",
        type: "line",
        source: `inspections_productive_units_productive_space_${plot._id}`,
        layout: {
          visibility: "visible",
          "line-join": "round", "line-cap": "round"
        },
        paint: {
          // "fill-color": "yellow", "fill-opacity": 0.4,
          "line-color": "yellow", "line-width": 2
        }
      })
    }
  }

  addCenterPoint(plot) {
    return new Promise((resolve, reject) => {
      this.map.loadImage(
        this.centerIconValue,
        (error, image) => {
          if (error) {
            reject(error);
          }

          // Add the image to the map style.
          this.map.addImage('center_icon', image);

          // Add a data source containing one point feature.
          this.map.addSource('center', {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': [
                {
                  'type': 'Feature',
                  'geometry': {
                    'type': 'Point',
                    'coordinates': [plot.entrance_point?.longitude ?? 0.1, plot.entrance_point?.latitude ?? 0.1]
                  }
                }
              ]
            }
          });

          // Add a layer to use the image to represent the data.
          this.map.addLayer({
            'id': 'marker_layer',
            'type': 'symbol',
            'source': 'center', // reference the data source
            'layout': {
              'icon-image': 'center_icon', // reference the image
              'icon-size': 1
            }
          });

          resolve()
        }
      );
    })
  }

  drawItems(plot) {
    const features = []
    let index = 1
    for (const item of plot.items) {
      if (item.itemable_type == "Crop" || item.itemable_type == "Animal") {
        continue;
      }

      const keys = Object.keys(item).filter((key) => key.includes('_details'))
      for (const key of keys) {
        const varieties = item[key]
        for (const variety of varieties) {
          const feature = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [variety.coordinates?.longitude, variety.coordinates?.latitude]
            },
            properties: {
              variety_id: index
            }
          }

          features.push(feature)
          index++
        }
      }
    }

    this.map.addSource('items', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features
      }
    });

    // this.map.addLayer({
    //   id: 'item-layer',
    //   type: 'circle',
    //   source: 'items',
    //   paint: {
    //     'circle-radius': 15,
    //     'circle-color': '#02adf6'
    //   },
    //   filter: ['==', '$type', 'Point']
    // })

    this.map.addLayer({
      id: 'item-id',
      type: 'symbol',
      source: 'items',
      layout: {
        'text-field': ['get', 'variety_id'],
        'text-size': 8,
        'text-anchor': 'center',
        'text-offset': [0, 0],
        'text-font': ['Open Sans Bold']
      },
      paint: {
        'text-color': '#eb0c0c'
      }
    })
  }

  async takeScreenShoot() {
    // return new Promise((resolve, reject) => {
    // this.map.once("render", () => {
    //   this.map.on("load", async () => {
    await this.sleep(1000)
    return this.map.getCanvas().toDataURL()
    // const canvas = await html2canvas(document.getElementById(this.map_container_id))
    // resolve(canvas.toDataURL())
    //   })
    // })
    // })

    // this.map.setBearing(map.getBearing());
  }

  sleep(time = 0) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve()
      }, time)
    })
  }

  async createPdf(images, totalDistance) {
    try {
      // return await post('/pdfs', {
      //   body: JSON.stringify({
      //       inspection_id: this.inspectionValue.id,
      //       total_distance: totalDistance,
      //       images: images
      //     }),
      //   contentType: "application/json",
      //   responseKind: 'blob'
      // })
      const currentUrl = new URL(window.location.href);
      const locale = currentUrl.pathname.split('/')[1];

      return fetch('/pdfs.pdf', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({
          inspection_id: this.inspectionValue._id,
          total_distance: totalDistance,
          images: images,
          locale
        })
      })
    } catch (e) {
      console.error(e)
    }
  }

  // async uploadScreenShoot(data, realmId) {
  //   try {
  //     await post('/pdfs/save_productive_space_map.json', {
  //       body: JSON.stringify({ image: data, realm_id: realmId }),
  //       responseKind: "json",
  //       headers: { 'Content-Type': 'application/json' }
  //     })
  //   } catch(e) {
  //     console.error(e)
  //   }
  // }

  getDistance() {
    let distance = 0
    const waypoints = this.inspectionValue.waypoints?.sort((a, b) => {
      a.timestamps - b.timestamps
    }).map(c => ({latitude: c.latitude, longitude: c.longitude, altitude: c.altitude}))

    for (let i = 0; i < waypoints.length - 1; i++) {
      distance += getDistance(waypoints[i], waypoints[i + 1], 0.01)
    }

    return distance.toFixed(2)
  }
}
