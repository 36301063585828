import {Controller} from "@hotwired/stimulus"
import {post} from "@rails/request.js"

// Connects to data-controller="questions"
export default class extends Controller {
  static targets = ["form"]

  connect() {
    console.log("Questions Controller Connected")
  }

  disconnect() {
    console.log("Questions Controller Disconnected")
    super.disconnect()
  }

  async excel(event) {
    //console.log("Excel")
    try {
      const data = new FormData(this.formTarget)
      const {ok} = await post(action, {body: data})
    } catch (e) {
      console.error({e})
    }
  }
}
