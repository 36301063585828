import NestedForm from "stimulus-rails-nested-form"

// Connects to data-controller="nested-form"
export default class extends NestedForm {
  connect() {
    super.connect()
  }

  add(t) {
    super.add(t)

    // t.preventDefault()
    // const e = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
    // this.targetTarget.insertAdjacentHTML("beforebegin", e)

    this.reset()
    const emitter = new CustomEvent("update")
    window.dispatchEvent(emitter)
  }

  reset() {
    const allWrappers = document.querySelectorAll('.nested-form-wrapper')
    const clone = allWrappers[allWrappers.length - 1]

    clone.querySelectorAll('input').forEach(($input) => {
      if (!['hidden', 'checkbox'].includes($input.type)) $input.value = ""
    })

    clone.querySelectorAll('select').forEach(($input) => {
      $input.value = ""
    })

    clone.querySelectorAll('textarea').forEach(($input) => {
      $input.value = ""
    })
  }
}
