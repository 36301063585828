import { Controller } from "@hotwired/stimulus";
import { Toast } from "bootstrap";

export default class extends Controller {
    static targets = ["toastContainer"];

    connect() {
        // console.log("Toast controller connected");
        // console.log("toastContainerTarget:", this.toastContainerTarget);
    }

    reconnect() {
        this.connect();
    }

    disconnect() {
        // console.log("Porque te desconectas??")
    }

    async export(event) {
        event.preventDefault();

        const url = event.currentTarget.href;

        const currentUrl = new URL(window.location.href);
        const params = currentUrl.searchParams.toString();

        const locale = currentUrl.pathname.split('/')[1];
        const companyId = currentUrl.pathname.split('/')[3];

        try {
            const response = await fetch(url, {
                headers: { Accept: "application/json" }
            })

            if (!response.ok) throw new Error('Error');
            let filename_match= response.headers.get('Content-Disposition').match(/filename="([^"]+)"/)

            const blob = await response.blob()
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = decodeURIComponent(filename_match[1]);
            link.click();

            const statusUrl = `/${locale}/companies/${companyId}/productive_spaces/export_status?${params}`;
            const responseStatusURL = await fetch(statusUrl, {
                headers: {Accept: "application/json"}
            })

            const data = await responseStatusURL.json()
            this.showToast(data.message);

        } catch (error) {
            console.error(error)
        }
    }

    showToast(message) {
      const toastHTML =`
        <div class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="true" data-bs-delay="5000">
          <div class="toast-header">
            <strong className="me-auto">${message.result}</strong>
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
          <div class="toast-body">
            <p>${message.exported_with_centroid}</p>
            <p>${message.exported_with_polygon}</p>
            <p>${message.not_exported}</p>
          </div>
        </div>`;
      this.toastContainerTarget.insertAdjacentHTML('beforeend', toastHTML);
      const toastElement = this.toastContainerTarget.querySelector('.toast:last-child');
      const toast = new Toast(toastElement);
      toast.show();
    }

    showErrorToast(message) {
        const toastHTML =`
            <div class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="true" data-bs-delay="5000">
                <div class="toast-header">
                    <strong className="me-auto">${message.error}</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    <p>${message.didnt_work}</p>
                </div>
            </div>`;

        this.toastContainerTarget.insertAdjacentHTML('beforeend', toastHTML);
        const toastElement = this.toastContainerTarget.querySelector('.toast:last-child');
        const toast = new Toast(toastElement);
        toast.show();
    }
}