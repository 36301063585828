import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="productive-spaces-norms"
export default class extends Controller {
  static values = {url: String}

  connect() {
  }

  async updateNorm(event) {
    event.preventDefault()
    event.target.form.submit()
  }

  async toggleNorm(event) {
    event.preventDefault()

    const body = {
      productive_space: {
        productive_spaces_norms_attributes: {
          '0': {
            norm_id: event.target.value,
            enabled: event.target.value
          }
        }
      }
    }

    // const request = new FetchRequest("patch", this.urlValue, { responseKind: "turbo-stream", body: body })
    // await request.perform()
  }
}
