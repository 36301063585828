import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="questionnaires"
export default class extends Controller {
  connect() {
  }

  toggleConsequenceDetail(event) {
    const {elementId} = event.params
    if (elementId) {
      const element = document.getElementById(elementId)
      element.classList.contains("d-none") ? element.classList.remove("d-none") : element.classList.add("d-none")
    }
  }
}
