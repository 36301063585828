import {Controller} from "@hotwired/stimulus"
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin'
// import Viewer from "viewerjs"

// Connects to data-controller="image-viewer"
export default class extends Controller {
  static values = {title: String, description: String, sources: Array}
  currentAngle = 0

  connect() {
    console.log('image viewer connected')

    const options = {
      pswpModule: () => import('photoswipe'),
      zoom: true,
    }

    this.lightbox = new PhotoSwipeLightbox(options)
    this.lightbox.on('uiRegister', () => this.initLightbox())
    this.lightbox.on('uiRegister', () => this.rotateLightbox())

    // this.viewer = new Viewer(this.element, {
    //   title: [1, (image) => this.setTitleFor(image, this.titleValue, this.descriptionValue)]
    // })
  }

  disconnect() {
    this.currentAngle = 0
    this.lightbox.destroy()
    // this.viewer.destroy()
  }

  show(event) {
    event.preventDefault()

    let {sources} = event.params
    if (this.sourcesValue.length) sources = this.sourcesValue

    let index = 0

    const captionPlugin = new PhotoSwipeDynamicCaption(this.lightbox, {
      // Plugins options, for example:
      mobileLayoutBreakpoint: 700,
      type: 'auto',
      mobileCaptionOverlapRatio: 1
    });

    try {
      const newIndex = parseInt(event.target.dataset.index)
      index = isNaN(newIndex) ? 0 : newIndex
    } catch (e) {
      console.warn(e)
    }

    this.lightbox.init()
    this.lightbox.loadAndOpen(index, sources)

    // const { description, title, original } = event.params
    // const index = this.viewer.images.findIndex((i) => i.src === original)
    // this.viewer.options.title = [1, (image) => this.setTitleFor(image, title, description)]
    // this.viewer.view(index)
  }

  initLightbox() {
    this.lightbox.pswp.ui.registerElement({
      name: 'custom-caption',
      order: 9,
      isButton: false,
      appendTo: 'root',
      html: 'Caption text',
      onInit: (el, pswp) => {
        this.lightbox.pswp.on('change', () => {
          const currSlideElement = this.lightbox.pswp.currSlide.data.element
          // console.log(this.lightbox.pswp.currSlide.data)
          let captionHTML = this.lightbox.pswp.currSlide.data.caption

          if (currSlideElement) {
            const hiddenCaption = currSlideElement.querySelector('.hidden-caption-content');

            if (hiddenCaption) {
              // get caption from element with class hidden-caption-content
              captionHTML = hiddenCaption.innerHTML;
            } else {
              // get caption from alt attribute
              captionHTML = currSlideElement.querySelector('img').getAttribute('alt');
            }
          }

          el.innerHTML = captionHTML || '';
        })
      }
    })
  }

  rotateLightbox() {
    this.lightbox.pswp.ui.registerElement({
      name: 'rotate',
      ariaLabel: 'Rotate',
      order: 9,
      isButton: true,
      html: "<span class='bi bi-arrow-clockwise' style='color:white;font-weight:bold'></span>",
      onClick: (event, el) => {
        this.currentAngle += 90
        this.lightbox.pswp.currSlide.content.element.style.transform = `rotate(${this.currentAngle}deg)`
      }
    })
  }
}
