import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js";

export default class extends Controller {
  static targets = ["select", "list"]

  connect() {
    this.changeCompaniesDeforestationVarieties()
  }
  disconnect() {
    super.disconnect()
  }

  async changeCompaniesDeforestationVarieties(event) {
    const deforestationId = event ? event.target.value : this.selectTarget.value;
    console.log(deforestationId)
    try {
      const result = await get(`/deforestations/${deforestationId}/varieties.json`, { headers: { 'Content-Type': 'application/json' } })
      const varieties = await result.json
      let list = ""
      for (const variety of varieties) {
        list += `<div class="row ms-0 me-0">
          <div class="col border p-3">
            <div class="row">
              <div class="col">
                <div class="form-floating">
                  <input class="form-control disabled" value=${variety.name}>
                </div>
              </div>
            </div>
          </div>
        </div>
        `
      }

      this.listTarget.innerHTML = list

    } catch(e) {
      console.error(e)
    }
  }
}