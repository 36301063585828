import {Controller} from "@hotwired/stimulus"
import {patch} from "@rails/request.js"

// Connects to data-controller="non-compliances"
export default class extends Controller {
  connect() {
  }

  disconnect() {
  }

  async open(event) {
    // event.target.form.requestSubmit()
    const {getUrl} = event.params
    const {action} = event.target.form
    const patchResponse = await patch(action, {body: new FormData(event.target.form), responseKind: "json"})
    if (patchResponse.ok) document.location.reload()
  }

  toggleCloseButton(event) {
    const {params} = event
    if (params.closed) return

    const selects = Array.from(document.querySelectorAll('select.sanctions'))
    const value = selects.filter((s) => !s.value)
    const closeButton = document.querySelector("input[type='radio'].closed")

    if (value.length === 0) {
      closeButton.removeAttribute('disabled')
    } else {
      closeButton.disabled = true
    }
  }
}
