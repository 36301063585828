import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="paginator"
export default class extends Controller {
  static targets = ["entries", "navigation"]

  initialize() {
    let options = {
      rootMargin: '200px',
    }

    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
  }

  connect() {
    this.intersectionObserver.observe(this.navigationTarget)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.navigationTarget)
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  loadMore() {
    let next_page = this.navigationTarget.querySelector("a[rel='next']")
    if (next_page == null) {
      return
    }
    let url = next_page.href

    fetch(url)
      .then((response) => {
        //console.log(response.json())
        response.json()
      })
      .then((data) => {
        console.log(data)
      })
      .catch((err) => console.error(err))
  }
}
