import {Controller} from "@hotwired/stimulus"
import {Modal} from "bootstrap"

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    const backdrop = document.querySelector(".modal-backdrop")
    if (backdrop) backdrop.remove()

    this.modal = new Modal(this.element, {keyboard: false})
    this.modal.show()

    this.element.addEventListener("hidden.bs.modal", (e) => this.element.remove())
  }

  disconnect() {
    this.modal.hide()
  }
}
