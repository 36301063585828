import {Controller} from "@hotwired/stimulus"
import Pagy from "../pagy-module"
import {FetchRequest} from "@rails/request.js";

// Connects to data-controller="pagy-initializer"
export default class extends Controller {
  connect() {
    Pagy.init(this.element)
  }

  async navigate(event) {
    event.preventDefault()
    //console.log({event})
    const fetchUrl = event.target.href
    if (!fetchUrl) return
    history.pushState({data_turbo_history: "replace"}, {}, fetchUrl)
    const request = new FetchRequest("get", fetchUrl, {responseKind: "turbo-stream"})
    await request.perform()
  }
}
