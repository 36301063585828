import {Controller} from "@hotwired/stimulus"
import {put} from "@rails/request.js"
import Sortable from "sortablejs"

// Connects to data-controller="sortable"
export default class extends Controller {
  static values = {url: String}

  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.onEnd.bind(this),
      handle: "[data-sortable-handle]"
    })
  }

  disconnect() {
    this.sortable.destroy()
  }

  onEnd(event) {
    // console.log({event})

    const {newIndex, item} = event
    const id = item.dataset.sortableId
    const url = this.urlValue.replace(":id", id)

    put(url, {body: {position: newIndex, reorder: true}})
  }
}
