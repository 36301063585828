import { Controller } from "@hotwired/stimulus"
import { put } from "@rails/request.js"
// Connects to data-controller="bundled-proposals"
export default class extends Controller {
  static targets = ["form", "totalStandalone"]
  static values = {
    originalStandaloneProposalIds: Array,
    bundledProposalId: Number,
    companyId: Number,
    bundledProposalIndexUrl: String
  }

  totalStandalone = 0

  connect() {
    console.log('bundled started')
    this.totalStandalone = this.originalStandaloneProposalIdsValue.length
    this.totalStandaloneTarget.innerText = this.totalStandalone
  }

  toggleStandalone(event) {
    if (event?.target) {
      const form = new FormData(this.formTarget)
      if (event.target.checked) {
        form.append("standalone_to_add[]", event.target.value)
      } else {
        form.append("standalone_to_remove[]", event.target.value)
      }

      this.update(form)
    }
  }

  toggleAll(event) {
    event.preventDefault()
    const form = new FormData(this.formTarget)
    const {params} = event

    const {checked} = event.target
    const elements = document.querySelectorAll(`input.${params.elementId}`)

    if (elements.length) {
      for (const element of elements) {
        element.checked = checked
        if (checked) {
          form.append("standalone_to_add[]", element.value)
        } else {
          form.append("standalone_to_remove[]", element.value)
        }
      }
    }

    this.update(form)
  }

  navigateToIndex() {
    window.location.href = this.bundledProposalIndexUrlValue
  }

  async cancel() {
    const form = new FormData()

    if (this.originalStandaloneProposalIdsValue.length > 0) {
      this.originalStandaloneProposalIdsValue?.forEach( id => {
        form.append("standalone_to_set[]", id)
      })
    } else {
      form.append("standalone_to_set[]", "")
    }

    await this.update(form)
    this.navigateToIndex()
  }

  async update(formData) {
    const url =  `/companies/${this.companyIdValue}/bundled_proposals/${this.bundledProposalIdValue}/update_standalone_proposals`

    try {
      const response = await put(url, { headers: {
        'Accept': 'application/json'
        },
        responseKind: "application/json",
        body: formData
      })

      const { json, ok } = await response

      if (ok) {
        const res = await json
        this.totalStandalone = res["total_standalone"]
        this.totalStandaloneTarget.innerText = this.totalStandalone
      }
    } catch(e) {
      console.error(e)
    }
  }

  updateTotalStandalone() {

  }
}
