import { Controller } from "@hotwired/stimulus"
import { put } from "@rails/request.js"

// Connects to data-controller="intervals"
export default class extends Controller {
  static values = { companyId: Number, intervalId: Number }
  connect() {
    console.log('intervals controller')
  }

  toggleContainer(event){
    if (event?.target) {
      const form = new FormData(this.formTarget)
      if (event.target.checked) {
        form.append("containers_to_add[]", event.target.value)
      } else {
        form.append("containers_to_remove[]", event.target.value)
      }

      this.update(form)
    }
  }

  toggleAll(event) {
    event.preventDefault()
    const form = new FormData(this.formTarget)
    const {params} = event

    const {checked} = event.target
    const elements = document.querySelectorAll(`input.${params.elementId}`)

    if (elements.length) {
      for (const element of elements) {
        if (!element.disabled) {
          element.checked = checked
          if (checked) {
            form.append("containers_to_add[]", element.value)
          } else {
            form.append("containers_to_remove[]", element.value)
          }
        }
      }
    }

    this.update(form)
  }


  async update(form) {
    const url = `/companies/${this.companyIdValue}/intervals/${this.intervalIdValue}/update_containers`

    try {
      await put(url, {
        headers: {
          'Accept': 'application/json'
        },
        responseKind: "application/json",
        body: form
      })

    } catch(e) {
      console.error(e)
    }
  }
}
