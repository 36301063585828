import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["email", "domain", "username"]

  connect() {
    // console.log("HOLA")
  }

  disconnect() {

  }

  change() {
    // console.log("HOLA")
    const username = this.usernameTargets[0]?.value;
    const domain = this.domainTargets[0]?.value;
    const email = `${username || ''}${domain || ''}`;
    this.emailTargets[0].value = email
  }
}
