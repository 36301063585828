import {Controller} from "@hotwired/stimulus"
// import { useMutation } from "stimulus-use"

// Connects to data-controller="empty-state"
export default class extends Controller {
  static targets = ["list", "table", "emptyState", "div"]

  // static classes = ["hide"]

  connect() {
    this.observer = new MutationObserver(this.update.bind(this))
    this.observer.observe(this.element, {
      childList: true,
      attributes: false,
      subtree: true
    })
    this.update()
  }

  disconnect() {
    this.observer.disconnect()
  }

  update() {
    if (this.listTarget.children.length > 0) {
      this.tableTarget.classList.remove("hidden")
      this.emptyStateTarget.classList.add("hidden")
    } else {
      this.tableTarget.classList.add("hidden")
      this.emptyStateTarget.classList.remove("hidden")
    }
  }
}
