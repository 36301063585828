import {Controller} from "@hotwired/stimulus"
import TomSelect from "tom-select"
import {get} from "@rails/request.js"

// Connects to data-controller="selectable"
export default class extends Controller {
  static values = {
    allowCreate: Boolean,
    allowDeselect: Boolean,
    allSelectedItems: Array,
    closeOnSelect: Boolean,
    disabled: Boolean,
    hideSelected: Boolean,
    maxItems: Number,
    maxOptions: Number,
    model: String,
    noResults: String,
    placeholder: String,
    searchable: Boolean,
    selectedItems: Array,
    url: String
  }

  connect() {
    console.log("Selectable Controller Connected")
    this._initialize()
  }

  disconnect() {
    if (this.selectable) this.selectable.destroy()
  }

  change(event) {
    fetch(`/entities/${event.target.value}.json`).catch(err => console.log(err))
  }

  showItemAlias(event) {
    const element = document.getElementById("item-alias")
    if (element)
      if (event.target.value !== null) {
        if (element.classList.contains("d-none")) element.classList.remove("d-none")
      } else element.classList.add("d-none")
  }

  allowDeselection(event) {
    event.stopImmediatePropagation()
    this.selectable.settings.allowDeselect = !!event.target.value
  }

  _initialize() {
    if (this.element) {
      const url = `${this.urlValue}.json`
      const plugins = ["remove_button", "dropdown_input"]

      this.selectable = new TomSelect(this.element, {
        plugins: plugins,
        valueField: "id",
        labelField: "name",
        searchField: ["name"],
        selectOnTab: true,
        placeholder: `${this.placeholderValue}`,
        preload: true,
        create: this.allowCreateValue ?? true,
        hideSelected: true,
        maxItems: this.maxItemsValue === 0 ? null : (this.maxItemsValue || 1),
        sortField: {field: "name", direction: "asc"},
        maxOptions: this.maxOptionsValue === 0 ? null : (this.maxOptionsValue || 1),
        items: this.selectedItemsValue || [],
        closeAfterSelect: this.closeOnSelectValue ?? true,
        render: {
          option: (data, escape) => {
            return `<div id="data-${escape(data.id)}">${escape(data.name)}</div>`
          },
          item: (data, escape) => {
            return `<div id="data-${escape(data.id)}">${escape(data.name)}</div>`
          },
          no_results: (data, escape) => {
            if (this.noResultsValue) {
              return `<div class="no-results">${escape(this.noResultsValue)}</div>`;
            }
            return `<div class="no-results">No results found for ${escape(data.input)}</div>`;
          },
        }
      })
    }
  }
  
}
