import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="turbo"
export default class extends Controller {
  static values = {id: Number}

  connect() {
    this.element.setAttribute("data-action", "click->turbo#click")
  }

  disconnect() {
  }

  click(event) {
    event.preventDefault()
    this.url = this.element.getAttribute("href")
    this.setUrl(this.url)
  }

  setUrl(destinationUrl) {
    fetch(destinationUrl, this.appendTurboToHeaders)
      .then(res => res.text())
      .then(
        (html) => {
          Turbo.renderStreamMessage(html)
          this.updateHistory("replace", destinationUrl)
        }
      )
  }

  change(event) {
    if (event.detail.success) {
      const {response} = event.detail.fetchResponse
      this.updateHistory("replace", response.url)
    }
  }

  updateHistory(action = null, destinationUrl = null) {
    const data = action ? {data_turbo_history: action} : {}
    history.pushState(data, null, destinationUrl)
    Turbo.navigator.history.push(destinationUrl)
  }

  appendTurboToHeaders(event) {
    let {headers} = event.detail.fetchOptions || {};

    if (headers) {
      headers.Accept = ["text/vnd.turbo-stream.html", headers.Accept].join(", ");
    }
  }
}
