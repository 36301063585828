// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "trix"
import "@rails/actiontext"
// import "mapkick/bundle"
// import * as jquery from "jquery"
import * as bootstrap from "bootstrap"
// import * as coreui from "@coreui/coreui"
// import "metismenu"

// window.jQuery = jquery
// window.$ = jquery
window.bootstrap = bootstrap
// window.coreui = coreui
// window.metismenu = metismenu
