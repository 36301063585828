import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="entities"
export default class extends Controller {
  connect() {
    console.log("entities controller connected")
  }

  disconnect() {
    console.log("entities controller disconnected")
  }

  toggleName(event) {
    const {input} = event.params
    const {checked} = event.target
    const element = document.getElementById(input)

    if (element) {
      if (checked) {
        element.removeAttribute("readonly")
      } else {
        element.setAttribute("readonly", true)
      }
    }
  }
}
